import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRb from "../../common/HeaderRb";
import { toast } from "react-toastify";
import { ContextConfig } from "../../App";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { savePrefs } from "../../api/GlobalApi";
import {
  usePrevious,
  isValidDate,
  addDays,
  formatDate,
  print,
  handleCsv,
  truncateString,
  isValid,
  formatMoney
} from "../../utils";
import {
  getReceiverList,
  getReceiverContents,
  getRecStoreList
} from "../../api/ReportsApi";
import { getAssignedStoresAsync } from "../../api/StoreApi";
import { debounce } from "../../fp";
import backButton from "../../assets/img/back-button-svgrepo-com.svg";
import "./receivers.css";

const dReceivers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [receiverData, setReceiverData] = useState([]);
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [stores, setStores] = useState([]);
  const [storeid, setStoreid] = useState("");
  const [optionsSelected, setOptionsSelected] = useState(false);
  const [trans, setTrans] = useState("");
  const context = useContext(ContextConfig);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const previousData = usePrevious(data);

  useEffect(() => {
    if (!isValid(storeid) || storeid.length === 0) {
      setStoreid(context.lastStoreid);
    }

    if (isValid(context.startDate)) {
      setStartDate(context.startDate);
    }

    if (isValid(context.endDate)) {
      setEndDate(context.endDate);
    }
    fetchStores();
  }, []);

  useEffect(() => {
    handleResetOptions();
  }, [storeid]);

  useEffect(() => {
    if (optionsSelected) {
      loadReciever();
    }
  }, [optionsSelected]);

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      loadData();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("sm-body");
    body.style.height = remaining + "px";
    body.style.overflow = "hidden";
  };

  const loadData = () => {
    if (!isValidDate(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }
    getReceiverList(
      context.basketUrl,
      context.basketApiKey,
      formatDate(context.startDate),
      formatDate(context.endDate),
      context.lastStoreid
    )
      .then((resp) => {
        setIsLoading(false);
        const j = resp.data;
        if (j.error === 0) {
          setData(j.items);
        } else {
          setData([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setData([]);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadReciever = () => {
    setIsLoading(true);
    getReceiverContents(
      context.basketUrl,
      context.lastStoreid,
      trans,
      context.basketApiKey
    )
      .then((resp) => {
        setIsLoading(false);
        const j = resp.data;
        if (j.error == "0") {
          setReceiverData(j.items);
        } else {
          setReceiverData([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        setReceiverData([]);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handlePrint = () => {
    // check to see if we are viewing the list of the contents
    if (optionsSelected) {
      print(document.getElementById("rec-contents").innerHTML);
    } else {
      print(document.getElementById("rec-list").innerHTML);
    }
  };

  const handleOptionsClick = (options) => {
    setTrans(options.f1032);
    setOptionsSelected(true);
    const contents = document.getElementById("rec-contents");
    // here is where we are handling the animating of the report details
    contents.classList.toggle("slidin");
    contents.classList.toggle("slidout");

    const masterNavBar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const rControls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    // const goBack = document.getElementById("go-back").getBoundingClientRect()
    //   .height;
    // const recInfo = document.getElementById("rec-info").getBoundingClientRect()
    //   .height;
    //const recListBody = document.getElementById("rec-list-body");
    const usedSpace = masterNavBar + rControls; // + goBack + recInfo;
    const remaining = window.innerHeight - usedSpace;
    contents.style.height = remaining - 50 + "px";
    contents.style.overflowY = "scroll";
    //recListBody.style.height = remaining + "px";
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    setStartDate(context.startDate);
    handleResetOptions();
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(context.endDate);
    handleResetOptions();
  };

  const fetchStores = () => {
    getAssignedStoresAsync(context.url, context.token).then((response) => {
      const j = response.data;
      if (j.error === 0) {
        loadStorelist(j.items);
        //setStores(j.items);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  const loadStorelist = (existingStores) => {
    getRecStoreList(context.basketUrl, context.basketApiKey)
      .then((resp) => {
        const j = resp.data;
        if (j.error == "0") {
          const serverStores = j.items;
          const mergedStoreList = mergeStores(existingStores, serverStores);
          setStores(mergedStoreList);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setData([]);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const mergeStores = (l1, l2) => {
    const newArray = [];
    l1.forEach((store) => {
      if (l2.includes(store.store_Number.toString().padStart(3, "0"))) {
        newArray.push(store);
      }
    });

    return newArray;
  };

  const handleSelectionChange = (e) => {
    context.lastStoreid = e.target.value;
    setStoreid(e.target.value);

    savePrefs(
      context.url,
      context.token,
      context.lastStoreid,
      context.lastGroup,
      context.lastSearchType,
      context.lastGroupName
    );
  };

  const handleResetOptions = () => {
    setOptionsSelected(false);
    loadData();

    const div = document.getElementById("rec-contents");

    if (div) {
      let isVisible = div.checkVisibility({
        checkOpacity: true,
        checkVisibilityCSS: true
      });
      if (isVisible) {
        div.classList.toggle("slidin");
        div.classList.toggle("slidout");
      }
    }
  };

  const formatPercent = (p) => {
    if (!p) {
      return "";
    }
    const pos = p.indexOf(".");
    const left = p.substring(0, pos);
    let right = p.substring(pos + 1);
    if (right.length > 0) {
      right = right.substring(0, 2);
    }
    return left + "." + right + "%";
  };

  const getDocumentDetails = () => {
    const firstRecord = receiverData[0];
    const details = {
      invoiceNumber: "",
      vendor: "",
      vendorId: "",
      operator: "",
      operatorName: "",
      store: "",
      invoiceDate: "",
      terminal: "",
      transaction: ""
    };
    // need to add date and store and trans number to this object
    if (firstRecord) {
      details.invoiceNumber = firstRecord["f1245"];
      details.vendor = firstRecord["f27"];
      details.vendorId = firstRecord["f334"];
      details.operator = firstRecord["f1126"];
      details.operatorName = firstRecord["f1127"];
      details.terminal = firstRecord["f1057"];
      details.store = firstRecord["f1056"];
      details.transaction = firstRecord["f1032"];
    }
    return details;
  };

  const totalColumn = (col) => {
    const total = receiverData.reduce((acc, cur) => {
      return acc + parseFloat(cur[col]);
    }, 0);
    return total;
  };

  const exportCsv = (filename) => {
    debugger;
    const div = document.getElementById("rec-contents");
    if (div) {
      let isVisible = div.checkVisibility({
        checkOpacity: true,
        checkVisibilityCSS: true
      });
      if (isVisible) {
        const header = getDocumentDetails();
        //const copy = [...header, receiverData];
        // let headerArr = [];
        // Object.keys(header).map((k) => {
        //   headerArr.push(header[k]);
        // });
        //copy.unshift.apply(copy, header);
        handleCsv(
          receiverData,
          "rec-" +
            header.store +
            "-" +
            header.transaction +
            "-" +
            header.invoiceNumber +
            ".csv"
        );
      } else {
        handleCsv(data, filename);
      }
    } else {
      handleCsv(data, filename);
    }
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRb></HeaderRb>
      <div id="r-controls">
        <h3 style={{ margin: "auto", width: "90%" }}>Reports</h3>
        <form className="form-inline" style={{ margin: "auto" }}>
          <div className="form-group row w-100">
            <div className="col-xs-12 col-sm-6 col-lg-3 m-auto">
              Selection Type:{" "}
              <select
                className="form-control"
                id="combodSelectionType"
                value={storeid}
                onChange={handleSelectionChange}
              >
                {stores.map((record, index) => (
                  <option key={index} value={record.storeid}>
                    {truncateString(record.store_Name, 40)}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              Start:&nbsp;
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              End:&nbsp;
              <DatePicker selected={endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </form>
        <div className="d-report-spacer">&nbsp;</div>
      </div>
      <div className="container-fluid" id="receivers">
        <div id="sm-controls">
          <div className="export-report">
            <span className="print-report" onClick={handlePrint}>
              <i className="far fa-print"></i>
            </span>
            <span
              className="csv-report"
              onClick={() => exportCsv("receivers.csv")}
            >
              <i className="far fa-save"></i>
            </span>
          </div>
        </div>
        <div id="sm-body" style={{ position: "relative" }}>
          <React.Fragment>
            {data.length === 0 ? (
              <div className="d-empty-report">
                There were no records returned
              </div>
            ) : (
              <React.Fragment>
                <div id="rec-contents" className="rec-overlay slidout">
                  <div
                    id="go-back"
                    style={{ margin: "auto", width: "90%" }}
                    onClick={() => handleResetOptions()}
                  >
                    <img
                      alt="back"
                      style={{ width: "50px", cursor: "pointer" }}
                      src={backButton}
                    />
                    <span style={{ marginLeft: "10px" }}>Go Back</span>
                  </div>
                  <div
                    id="rec-info"
                    style={{
                      width: "90%",
                      margin: "auto",
                      marginBottom: "10px",
                      fontSize: "1.2rem",
                      color: "#fff"
                    }}
                  >
                    <div>
                      Invoice #:{getDocumentDetails().invoiceNumber}
                      <span>
                        {" "}
                        Transaction: {getDocumentDetails().transaction}
                      </span>
                    </div>
                    <div>
                      <span>Vendor: {getDocumentDetails().vendor}</span>
                      <span>{getDocumentDetails().vendorId}</span>
                    </div>
                    <div>
                      Operator: {getDocumentDetails().operator}{" "}
                      <span>{getDocumentDetails().operatorName}</span>
                    </div>
                    <div>
                      Location: {getDocumentDetails().store}
                      <span>{getDocumentDetails().terminal}</span>
                    </div>
                  </div>
                  <div id="main-table">
                    <table
                      style={{ width: "90%", margin: "auto" }}
                      className="rec-table"
                    >
                      <thead id="rec-thead">
                        <tr>
                          <th className="rec-table-center">Line</th>
                          <th>UPC</th>
                          <th className="rec-table-center">Case Pk</th>
                          <th>Description</th>
                          <th className="rec-table-center">Cases</th>
                          <th className="rec-table-center">Units</th>
                          <th className="rec-table-right">UCost</th>
                          <th className="rec-table-right">Ext Cost</th>
                          <th className="rec-table-right">Retail</th>
                          <th className="rec-table-right">Ext Retail</th>
                          <th className="rec-table-center">GM</th>
                          <th className="rec-table-center">Free</th>
                          <th className="rec-table-center">Return</th>
                        </tr>
                      </thead>
                      <tbody id="rec-list-body">
                        {receiverData.map((record, index) => (
                          <tr key={index}>
                            <td className="rec-table-center">{record.f1101}</td>
                            <td>{record.plu}</td>
                            <td style={{ textAlign: "center" }}>
                              {record.casePk}
                            </td>
                            <td>{record.description}</td>
                            <td className="rec-table-center">{record.cases}</td>
                            <td className="rec-table-center">{record.units}</td>
                            <td className="rec-table-right">
                              {formatMoney(record.ucost)}
                            </td>
                            <td className="rec-table-right">
                              {formatMoney(record.ecost)}
                            </td>
                            <td className="rec-table-right">
                              {formatMoney(record.retail)}
                            </td>
                            <td className="rec-table-right">
                              {formatMoney(record.eretail)}
                            </td>
                            <td className="rec-table-center">
                              {formatPercent(record.margin)}
                            </td>
                            <td className="rec-table-center">{record.f1247}</td>
                            <td className="rec-table-center">{record.f1248}</td>

                            <td></td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="4">&nbsp;</td>
                          <td colSpan="9">
                            <hr style={{ backgroundColor: "#fff" }} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">&nbsp;</td>
                          <td
                            className="rec-table-center"
                            style={{ fontWeight: "bold" }}
                          >
                            {totalColumn("cases")}
                          </td>
                          <td
                            className="rec-table-center"
                            style={{ fontWeight: "bold" }}
                          >
                            {totalColumn("units")}
                          </td>
                          <td
                            className="rec-table-right"
                            style={{ fontWeight: "bold" }}
                          >
                            {formatMoney(totalColumn("ucost"))}
                          </td>
                          <td
                            className="rec-table-right"
                            style={{ fontWeight: "bold" }}
                          >
                            {formatMoney(totalColumn("ecost"))}
                          </td>
                          <td
                            className="rec-table-right"
                            style={{ fontWeight: "bold" }}
                          >
                            {formatMoney(totalColumn("retail"))}
                          </td>
                          <td
                            className="rec-table-right"
                            style={{ fontWeight: "bold" }}
                          >
                            {formatMoney(totalColumn("eretail"))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <div style="min-height:50px">&nbsp;</div> */}
                  </div>
                </div>
                {/*  ) : ( */}
                <div id="rec-list">
                  <table style={{ width: "90%", margin: "auto" }}>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Store</th>
                        <th>Trans</th>
                        <th>Vendor Id</th>
                        <th>Name</th>
                        <th>Invoice #</th>
                        <th className="rec-table-center">Items</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((record, index) => (
                        <tr
                          key={index}
                          className="rec-row"
                          onClick={() => handleOptionsClick(record)}
                        >
                          <td>{formatDate(record.f254)}</td>
                          <td>{record.f1056}</td>
                          <td>{record.f1032}</td>
                          <td>{record.f27}</td>
                          <td>{record.f334}</td>
                          <td>{record.f1245}</td>
                          <td style={{ textAlign: "center" }}>
                            {record.f1101}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/*  )} */}
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default dReceivers;
